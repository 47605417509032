import { action, asyncAction } from '@play-co/replicant';

import { FirstSessionAssetKey } from '../chatbot/messageTemplates';
import { MutableState, SyncActionAPI } from '../defs/replicant';
import { sendFirstSessionOA } from './pets';

// Do not allow cheats in production
export const cheatActions = process.env.IS_DEVELOPMENT
    ? {
          sendFirstSessionOA: asyncAction(
              async (state: MutableState, args: { id: FirstSessionAssetKey }, api: SyncActionAPI) => {
                  //   const token = await createLoginTokenMultiUse(api);
                  await sendFirstSessionOA(state, api, args.id);
              },
          ),
          cheatSetClockOffset: action((state, args: { offset: number }, api: any) => {
              api.setClockOffset(args.offset);
          }),
          cheatGetClockOffset: action((state, _, api: any) => api.getClockOffset()),
      }
    : null;
